import { route } from "preact-router";
import { ctx } from ".";
import { CartItem } from "./model";
import { html } from "htm/preact";



export interface CartResponse {
    message: string;
    url: string;
    data: { [key: string]: string },
    data_array: { key: string, value: string }[]
}

export async function punchoutCart(data: { items: CartItem[], currency: string }, custom: Record<string, any>): Promise<CartResponse> {


    const res = await (await fetch('https://punchout.cloud/cart/' + localStorage.getItem('punchout_id'), {
        body: JSON.stringify({ cart: { Catalog: data }, custom }),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },

    })).text();

    try {
        return JSON.parse(res);
    } catch (e) {
        throw new Error(res);
    }
}

class Punchout {

    _block: any;

    async submitCart(cart: { items: CartItem[], currency: string }, custom: Record<string, any>) {
        try {
            this.showBlock();
            const res = await punchoutCart(cart, custom);
            if (!res.url || !res.data) {
                alert(res.message || 'An error happened with punchout');
                this.hideBlock();
                return;
            }
            const form = this.createPunchoutForm(res);
            localStorage.clear();

            form.submit();
            form.onerror = () => this.hideBlock();
            form.onsubmit = () => localStorage.removeItem('punchoutSessionId');
        } catch (error) {
            console.log("ERROR", error);
            alert((error as any).message);
            this.hideBlock();
        }
    }


    createPunchoutForm({ data_array, data, url }: { url: string, data: any, data_array: { key: string, value: string }[] }) {
        const form = document.createElement('form');
        form.setAttribute('hidden', 'true');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', url);
        if (data_array.length) {
            data_array.forEach(({ key, value }) => {
                const input1 = document.createElement('input');
                input1.setAttribute('type', 'text');
                input1.setAttribute('name', key);
                input1.setAttribute('value', value);
                form.appendChild(input1);
            });
        } else {
            for (const key in data) {
                const input = document.createElement('input');
                input.setAttribute('type', 'text');
                input.setAttribute('name', key);
                input.setAttribute('value', data[key]);
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        return form;
    }


    showBlock() {
        this._block = this._block || this.createBlock();
        document.body.appendChild(this._block);
    }

    hideBlock() {
        this._block = this._block || this.createBlock();
        document.body.removeChild(this._block);
    }

    createBlock() {
        const block = document.createElement('div');
        block.classList.add('punchout-block');
        const loading = document.createElement('div');
        loading.classList.add('punchout-loading');
        loading.appendChild(document.createElement('div'));
        loading.appendChild(document.createElement('div'));
        block.appendChild(loading);
        return block;
    }

}


export const punchout = new Punchout();



export function Login({ punchout_id }: { punchout_id: string }) {

    const { state, dispatch } = ctx();
    localStorage.setItem('punchout_id', punchout_id);
    dispatch({ type: 'cart.clear' });
    dispatch({ type: 'filter.clear' });

    route('/');
    return html``;
}
